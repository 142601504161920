import React, { createContext, useContext, useState, useEffect } from 'react';
import { oneOfType, arrayOf, node, object } from 'prop-types';

const Context = createContext();

const filterStudents = ({
    students,
    selectedGroup,
    selectedStudent,
    selectedTrafficLight,
    selectedCourseFilter,
    myCoursesFilter,
    allCoursesFilter,
    selectedSubject,
    selectedStatus,
    selectedCompetency,
    onlyShowNeedsPlanning,
    onlyShowFocusCredit,
}) => {
    return students
        .map(student => {
            return {
                ...student,
                courses:
                    student.courses &&
                    student.courses
                        .map(course => {
                            let isMatch = true;

                            if (myCoursesFilter && isMatch) {
                                isMatch = course.myCourse;
                            }

                            if (selectedCourseFilter !== '' && isMatch) {
                                isMatch = course.name === selectedCourseFilter;
                            }

                            if (selectedSubject && isMatch) {
                                isMatch = course.subject === selectedSubject;
                            }

                            if (selectedCompetency && isMatch) {
                                isMatch =
                                    course.tasks &&
                                    course.tasks.some(
                                        t =>
                                            t.competencies &&
                                            t.competencies.indexOf(selectedCompetency) >= 0,
                                    );
                            }

                            if (onlyShowFocusCredit && isMatch) {
                                isMatch = course.isFocusCredit;
                            }

                            const updatedCourse = {
                                ...course,
                                tasks: course.tasks.filter(task => {
                                    if (selectedStatus && selectedStatus !== task.status) {
                                        return false;
                                    }
                                    return true;
                                }),
                            };

                            return isMatch ? updatedCourse : null;
                        })
                        .filter(course => course),
            };
        })
        .filter(student => {
            let isMatch = true;
            if (selectedGroup && isMatch) {
                isMatch = student.group === selectedGroup || student.studentGroups.some(group => group.name === selectedGroup);
            }
            if (selectedStudent && isMatch) {
                isMatch = student.name === selectedStudent.name;
            }
            if (selectedTrafficLight && isMatch) {
                isMatch = student.trafficLight === selectedTrafficLight;
            }
            if (!(allCoursesFilter && selectedCourseFilter === '') && isMatch) {
                isMatch = student.courses && student.courses.length > 0;
            }
            if (selectedSubject && isMatch) {
                isMatch = student.courses && student.courses.length > 0;
            }
            if (onlyShowNeedsPlanning && isMatch) {
                isMatch = student.courses && !student.courses.some(course => course.plannedTask);
            }
            if (onlyShowFocusCredit && isMatch) {
                isMatch = student.courses && student.courses.length > 0;
            }
            if (selectedCompetency && isMatch) {
                isMatch = student.courses && student.courses.length > 0;
            }
            return isMatch;
        });
};

export const DataProvider = ({ data, children }) => {
    const [students, setStudents] = useState(data);
    const [staffId, setStaffId] = useState('1');
    const [courses, setCourses] = useState([]);
    const [selectedTrafficLight, setSelectedTrafficLight] = useState('');
    const [selectedGroup, setSelectedGroup] = useState('');
    const [selectedStudent, setSelectedStudent] = useState();
    const [selectedCompetency, setSelectedCompetency] = useState();
    const [studentDetailIndex, setStudentDetailIndex] = useState(-1); // which student detail panel is currently showing
    const [selectedCourseFilter, setSelectedCourseFilter] = useState('');
    const [myCoursesFilter, setMyCoursesFilter] = useState(true);
    const [allCoursesFilter, setAllCoursesFilter] = useState(false);
    const [selectedSubject, setSelectedSubject] = useState('');
    const [selectedStatus, setSelectedStatus] = useState('');
    const [onlyShowNeedsPlanning, setOnlyShowNeedsPlanning] = useState(false);
    const [onlyShowFocusCredit, setOnlyShowFocusCredit] = useState(false);
    const [studentConferenceModalData, setStudentConferenceModalData] = useState(null);
    const [isStudentConferenceModalShow, setIsStudentConferenceModalShow] = useState(false);

    useEffect(() => {
        setFilteredStudents(
            filterStudents({
                students: data,
                selectedGroup,
                selectedStudent,
                selectedTrafficLight,
                selectedCourseFilter,
                myCoursesFilter,
                allCoursesFilter,
                selectedSubject,
                selectedStatus,
                selectedCompetency,
                onlyShowNeedsPlanning,
                onlyShowFocusCredit,
            }),
        );
    }, [data]);

    useEffect(() => {
        updateCourses();
    }, [students, myCoursesFilter]);

    const [filteredStudents, setFilteredStudents] = useState(
        filterStudents({
            students: data,
            selectedGroup,
            selectedStudent,
            selectedTrafficLight,
            selectedCourseFilter,
            myCoursesFilter,
            allCoursesFilter,
            selectedSubject,
            selectedStatus,
            selectedCompetency,
            onlyShowNeedsPlanning,
            onlyShowFocusCredit,
        }),
    );

    const setPlannedTask = (student, course, taskId, notes) => {
        const nextStudents = students.map(x => {
            if (x.name === student.name) {
                return {
                    ...x,
                    courses: x.courses.map(c => {
                        if (c.name === course.name) {
                            return {
                                ...c,
                                tasks: c.tasks.map(task => {
                                    if (task.id === taskId) {
                                        return {
                                            ...task,
                                            notes,
                                        };
                                    }
                                    return task;
                                }),
                                plannedTask: taskId,
                            };
                        }
                        return c;
                    }),
                };
            }
            return x;
        });
        setStudents(nextStudents);
        setFilteredStudents(
            filterStudents({
                students: nextStudents,
                selectedGroup,
                selectedStudent,
                selectedTrafficLight,
                selectedSubject,
                selectedStatus,
                selectedCourseFilter,
                myCoursesFilter,
                allCoursesFilter,
                selectedCompetency,
                onlyShowNeedsPlanning,
                onlyShowFocusCredit,
            }),
        );
    };

    const setNotesForStudent = (studentId, notes) => {
        const nextStudents = students.map(student => {
            if (student.id === studentId) {
                return {
                    ...student,
                    notes,
                };
            }
            return student;
        });
        setStudents(nextStudents);
        setFilteredStudents(
            filterStudents({
                students: nextStudents,
                selectedGroup,
                selectedStudent,
                selectedTrafficLight,
                selectedSubject,
                selectedStatus,
                selectedCourseFilter,
                myCoursesFilter,
                allCoursesFilter,
                selectedCompetency,
                onlyShowNeedsPlanning,
                onlyShowFocusCredit,
            }),
        );
    };

    const saveNotesForTask = (student, course, taskId, notes) => {
        const nextStudents = students.map(x => {
            if (x.name === student.name) {
                return {
                    ...x,
                    courses: x.courses.map(c => {
                        if (c.name === course.name) {
                            return {
                                ...c,
                                tasks: c.tasks.map(task => {
                                    if (task.id === taskId) {
                                        return {
                                            ...task,
                                            notes,
                                        };
                                    }
                                    return task;
                                }),
                            };
                        }
                        return c;
                    }),
                };
            }
            return x;
        });
        setStudents(nextStudents);
        setFilteredStudents(
            filterStudents({
                students: nextStudents,
                selectedGroup,
                selectedStudent,
                selectedTrafficLight,
                selectedSubject,
                selectedStatus,
                selectedCourseFilter,
                myCoursesFilter,
                allCoursesFilter,
                selectedCompetency,
                onlyShowNeedsPlanning,
                onlyShowFocusCredit,
            }),
        );
    };

    const updateTasksForCourse = (student, course, tasks) => {
        const nextStudents = students.map(x => {
            if (x.name === student.name) {
                return {
                    ...x,
                    courses: x.courses.map(c => {
                        if (c.name === course.name) {
                            return {
                                ...c,
                                tasks: [...tasks],
                            };
                        }
                        return c;
                    }),
                };
            }
            return x;
        });
        setStudents(nextStudents);
        setFilteredStudents(
            filterStudents({
                students: nextStudents,
                selectedGroup,
                selectedStudent,
                selectedTrafficLight,
                selectedSubject,
                selectedStatus,
                selectedCourseFilter,
                myCoursesFilter,
                allCoursesFilter,
                selectedCompetency,
                onlyShowNeedsPlanning,
                onlyShowFocusCredit,
            }),
        );
    };

    const groups = [...new Set(students.reduce((acc, student) => {
        acc.push(student.group);
        student.studentGroups.forEach(group => acc.push(group.name));
        return acc;
    }, []))].sort();


    const updateCourses = () => {
        const newCourses = new Set(
            students.reduce((accumulator, current) => {
                if (current.courses) {
                    current.courses.forEach(course => {
                        if (myCoursesFilter && !course.myCourse) return;
                        accumulator.push(course.name);
                    });
                }
                return accumulator;
            }, []),
        );

        setCourses([...newCourses]);
    };

    const competencies = [
        ...new Set(
            students.reduce((accumulator, current) => {
                if (current.courses) {
                    current.courses.forEach(course => {
                        course.tasks.forEach(task => {
                            if (task.competencies) {
                                accumulator = accumulator.concat(task.competencies);
                            }
                        });
                    });
                }
                return accumulator;
            }, []),
        ),
    ];

    const toggleStudentDetail = index => {
        if (index === studentDetailIndex) {
            setStudentDetailIndex(-1);
        } else {
            setStudentDetailIndex(index);
        }
    };

    const setGroupFilter = value => {
        setSelectedGroup(value);
        setFilteredStudents(
            filterStudents({
                students,
                selectedGroup: value,
                selectedStudent,
                selectedTrafficLight,
                selectedSubject,
                selectedStatus,
                selectedCourseFilter,
                myCoursesFilter,
                allCoursesFilter,
                selectedCompetency,
                onlyShowNeedsPlanning,
                onlyShowFocusCredit,
            }),
        );
    };

    const setStudentFilter = value => {
        setSelectedStudent(value);
        setFilteredStudents(
            filterStudents({
                students,
                selectedGroup,
                selectedStudent: value,
                selectedTrafficLight,
                selectedSubject,
                selectedStatus,
                selectedCourseFilter,
                myCoursesFilter,
                allCoursesFilter,
                selectedCompetency,
                onlyShowNeedsPlanning,
                onlyShowFocusCredit,
            }),
        );
    };

    const setTrafficLightFilter = value => {
        setSelectedTrafficLight(value);
        setFilteredStudents(
            filterStudents({
                students,
                selectedGroup,
                selectedStudent,
                selectedTrafficLight: value,
                selectedSubject,
                selectedStatus,
                selectedCourseFilter,
                myCoursesFilter,
                allCoursesFilter,
                selectedCompetency,
                onlyShowNeedsPlanning,
                onlyShowFocusCredit,
            }),
        );
    };

    const setCompetencyFilter = value => {
        setSelectedCompetency(value);
        setFilteredStudents(
            filterStudents({
                students,
                selectedGroup,
                selectedStudent,
                selectedTrafficLight,
                selectedSubject,
                selectedStatus,
                selectedCourseFilter,
                myCoursesFilter,
                allCoursesFilter,
                selectedCompetency: value,
                onlyShowNeedsPlanning,
                onlyShowFocusCredit,
            }),
        );
    };

    const setCourseFilter = value => {
        setSelectedCourseFilter(value);
        setFilteredStudents(
            filterStudents({
                students,
                selectedGroup,
                selectedStudent,
                selectedTrafficLight,
                selectedSubject,
                selectedStatus,
                selectedCourseFilter: value,
                myCoursesFilter,
                allCoursesFilter,
                selectedCompetency,
                onlyShowNeedsPlanning,
                onlyShowFocusCredit,
            }),
        );
    };

    const setShowMyCoursesFilter = value => {
        setMyCoursesFilter(value);
        setAllCoursesFilter(!value);
        setSelectedCourseFilter('');
        setFilteredStudents(
            filterStudents({
                students,
                selectedGroup,
                selectedStudent,
                selectedTrafficLight,
                selectedSubject,
                selectedStatus,
                selectedCourseFilter: '',
                myCoursesFilter: value,
                allCoursesFilter: !value,
                selectedCompetency,
                onlyShowNeedsPlanning,
                onlyShowFocusCredit,
            }),
        );
    };

    const setShowAllCoursesFilter = value => {
        setAllCoursesFilter(value);
        setMyCoursesFilter(!value);
        setSelectedCourseFilter('');
        setFilteredStudents(
            filterStudents({
                students,
                selectedGroup,
                selectedStudent,
                selectedTrafficLight,
                selectedSubject,
                selectedStatus,
                selectedCourseFilter: '',
                myCoursesFilter: !value,
                allCoursesFilter: value,
                selectedCompetency,
                onlyShowNeedsPlanning,
                onlyShowFocusCredit,
            }),
        );
    };

    const setSubjectFilter = value => {
        setSelectedSubject(value);
        setFilteredStudents(
            filterStudents({
                students,
                selectedGroup,
                selectedStudent,
                selectedTrafficLight,
                selectedSubject: value,
                selectedStatus,
                selectedCourseFilter,
                myCoursesFilter,
                allCoursesFilter,
                selectedCompetency,
                onlyShowNeedsPlanning,
                onlyShowFocusCredit,
            }),
        );
    };

    const setStatusFilter = value => {
        setSelectedStatus(value);
        setFilteredStudents(
            filterStudents({
                students,
                selectedGroup,
                selectedStudent,
                selectedTrafficLight,
                selectedSubject,
                selectedStatus: value,
                selectedCourseFilter,
                myCoursesFilter,
                allCoursesFilter,
                selectedCompetency,
                onlyShowNeedsPlanning,
                onlyShowFocusCredit,
            }),
        );
    };

    const setNeedsPlanningFilter = value => {
        setOnlyShowNeedsPlanning(value);
        setFilteredStudents(
            filterStudents({
                students,
                selectedGroup,
                selectedStudent,
                selectedTrafficLight,
                selectedSubject,
                selectedStatus,
                selectedCourseFilter,
                myCoursesFilter,
                allCoursesFilter,
                onlyShowNeedsPlanning: value,
                onlyShowFocusCredit,
            }),
        );
    };

    const setFocusCreditFilter = value => {
        setOnlyShowFocusCredit(value);
        setFilteredStudents(
            filterStudents({
                students,
                selectedGroup,
                selectedStudent,
                selectedTrafficLight,
                selectedSubject,
                selectedStatus,
                selectedCourseFilter,
                myCoursesFilter,
                allCoursesFilter,
                onlyShowNeedsPlanning,
                onlyShowFocusCredit: value,
            }),
        );
    };

    const clearFilters = () => {
        setSelectedTrafficLight('');
        setSelectedGroup('');
        setOnlyShowNeedsPlanning(false);
        setOnlyShowFocusCredit(false);
        setSelectedSubject('');
        setSelectedStatus('');
        setCourseFilter('');
        setShowMyCoursesFilter(true);
        setShowAllCoursesFilter(false);
        setSelectedStudent('');
        setSelectedCompetency('');
        setFilteredStudents(
            filterStudents({
                students,
                selectedCourseFilter: '',
                myCoursesFilter: true,
                allCoursesFilter: false,
            }),
        );
    };

    const setConferenceForStudent = (studentId, conference) => {
        const nextStudents = students.map(student => {
            if (student.id === studentId) {
                const conferenceIndex = student.conferences.findIndex(
                    conf => conf.id === conference.id,
                );

                if (conferenceIndex === -1) {
                    return {
                        ...student,
                        conferences: [...student.conferences, conference],
                    };
                } else {
                    student.conferences[conferenceIndex] = conference;
                    return student;
                }
            }
            return student;
        });
        setStudents(nextStudents);
        setFilteredStudents(
            filterStudents({
                students: nextStudents,
                selectedGroup,
                selectedStudent,
                selectedTrafficLight,
                selectedSubject,
                selectedStatus,
                selectedCourseFilter,
                myCoursesFilter,
                allCoursesFilter,
                selectedCompetency,
                onlyShowNeedsPlanning,
                onlyShowFocusCredit,
            }),
        );
    };

    const deleteConferenceForStudent = (studentId, conferenceId) => {
        const nextStudents = students.map(student => {
            if (student.id === studentId) {
                return {
                    ...student,
                    conferences: student.conferences.filter(
                        conference => conference.id !== conferenceId,
                    ),
                };
            }
            return student;
        });
        setStudents(nextStudents);
        setFilteredStudents(
            filterStudents({
                students: nextStudents,
                selectedGroup,
                selectedStudent,
                selectedTrafficLight,
                selectedSubject,
                selectedStatus,
                selectedCourseFilter,
                myCoursesFilter,
                allCoursesFilter,
                selectedCompetency,
                onlyShowNeedsPlanning,
                onlyShowFocusCredit,
            }),
        );
    };

    const openStudentConferenceModal = (student, conferenceId) => {
        const conference =
            conferenceId && student.conferences.find(conference => conference.id === conferenceId);
        setStudentConferenceModalData({
            student,
            conference,
        });
        setIsStudentConferenceModalShow(true);
    };

    const closeStudentConferenceModal = () => {
        setStudentConferenceModalData(null);
        setIsStudentConferenceModalShow(false);
    };

    return (
        <Context.Provider
            value={{
                students,
                staffId,
                filteredStudents,
                toggleStudentDetail,
                studentDetailIndex,
                setPlannedTask,
                selectedTrafficLight,
                selectedGroup,
                selectedStudent,
                selectedCourseFilter,
                myCoursesFilter,
                allCoursesFilter,
                saveNotesForTask,
                selectedSubject,
                selectedStatus,
                selectedCompetency,
                onlyShowNeedsPlanning,
                onlyShowFocusCredit,
                setNeedsPlanningFilter,
                setFocusCreditFilter,
                groups,
                courses,
                competencies,
                setStaffId,
                setGroupFilter,
                setStudentFilter,
                setTrafficLightFilter,
                setCompetencyFilter,
                setCourseFilter,
                setShowMyCoursesFilter,
                setShowAllCoursesFilter,
                setSubjectFilter,
                setStatusFilter,
                clearFilters,
                setNotesForStudent,
                updateTasksForCourse,
                setConferenceForStudent,
                deleteConferenceForStudent,
                studentConferenceModalData,
                isStudentConferenceModalShow,
                openStudentConferenceModal,
                closeStudentConferenceModal,
            }}
        >
            {children}
        </Context.Provider>
    );
};

Context.propTypes = {
    children: oneOfType([arrayOf(node), node]),
    data: arrayOf(object),
};

export const useDataContext = () => {
    const context = useContext(Context);
    if (!context) {
        throw new Error(
            'This component can only be rendered as a child of the DataProvider component',
        );
    }
    return context;
};
