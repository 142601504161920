import React from 'react';
import TypeAhead from '../../../components/TypeAhead.js';

import './student-filters.scss';
import { useDataContext } from '../../../components/DataContext.js';

const StudentFilters = ({ students }) => {
    const {
        groups,
        courses,
        competencies,
        selectedStudent,
        selectedTrafficLight,
        selectedGroup,
        selectedCourseFilter,
        myCoursesFilter,
        allCoursesFilter,
        selectedCompetency,
        selectedSubject,
        selectedStatus,
        onlyShowNeedsPlanning,
        onlyShowFocusCredit,

        setGroupFilter,
        setStudentFilter,
        setTrafficLightFilter,
        setCompetencyFilter,
        setCourseFilter,
        setShowMyCoursesFilter,
        setShowAllCoursesFilter,
        setSubjectFilter,
        setStatusFilter,
        clearFilters,
        setNeedsPlanningFilter,
        setFocusCreditFilter,
    } = useDataContext();

    const onChangeGroup = evt => {
        setGroupFilter(evt.target.value);
    };

    const onChangeTrafficLight = evt => {
        setTrafficLightFilter(evt.target.value);
    };

    const onChangeCourseFilter = evt => {
        setCourseFilter(evt.target.value);
    };

    const onChangeSubject = evt => {
        setSubjectFilter(evt.target.value);
    };

    const onChangeStatus = evt => {
        setStatusFilter(evt.target.value);
    };

    const onChangeNeedsPlanning = evt => {
        setNeedsPlanningFilter(evt.target.checked);
    };

    const onChangeFocusCredit = evt => {
        setFocusCreditFilter(evt.target.checked);
    };

    const onAllCoursesSelect = () => {
        setShowAllCoursesFilter(true);
    };

    const onMyCoursesSelect = () => {
        setShowMyCoursesFilter(true);
    };

    return (
        <div className="student-filters">
            <div className="student-filters__left">
                <div className="student-filters__course-filter">
                    <div className="student-filters__course-radio">
                        <div>
                            <input
                                type="radio"
                                id="my_courses"
                                name="course_radio"
                                value="my"
                                checked={myCoursesFilter}
                                onChange={onMyCoursesSelect}
                            />
                            <label
                                className="student-filters__course-radio-label"
                                htmlFor="my_courses"
                            >
                                My Students
                            </label>
                        </div>
                        <div>
                            <input
                                type="radio"
                                id="all_courses"
                                name="course_radio"
                                value="all"
                                checked={allCoursesFilter}
                                onChange={onAllCoursesSelect}
                            />
                            <label
                                className="student-filters__course-radio-label"
                                htmlFor="all_courses"
                            >
                                All Students
                            </label>
                        </div>
                    </div>
                    <select
                        className="select student-filters__course-select"
                        name="course-filter"
                        id="course-filter"
                        value={selectedCourseFilter}
                        onChange={onChangeCourseFilter}
                    >
                        <option value="">Filter by Course</option>
                        {courses.sort().map(course => (
                            <option key={course} value={course}>
                                {course}
                            </option>
                        ))}
                    </select>
                </div>
                <select
                    className="select"
                    name="traffic-light-filter"
                    id="traffic-light-filter"
                    value={selectedTrafficLight}
                    onChange={onChangeTrafficLight}
                >
                    <option value="">Filter by Traffic Light</option>
                    <option value="Green">Green</option>
                    <option value="Yellow">Yellow</option>
                    <option value="Red">Red</option>
                </select>
                <select
                    value={selectedGroup}
                    className="select"
                    name="group-filter"
                    id="group-filter"
                    onChange={onChangeGroup}
                >
                    <option value="">Filter by Group</option>
                    {groups.map(group => (
                        <option key={group} value={group}>
                            {group}
                        </option>
                    ))}
                </select>
                <TypeAhead
                    items={students}
                    prop="name"
                    selectedValue={selectedStudent}
                    onChange={setStudentFilter}
                    placeholder="Filter by Student"
                />
                <select
                    className="select"
                    name="subject-filter"
                    id="subject-filter"
                    value={selectedSubject}
                    onChange={onChangeSubject}
                >
                    <option value="">Filter by Subject</option>
                    <option value="Math">Math</option>
                    <option value="ELA">ELA</option>
                    <option value="Social Studies">Social Studies</option>
                    <option value="Science">Science</option>
                    <option value="Art">Art</option>
                    <option value="Foreign Language">Foreign Language</option>
                </select>
                <TypeAhead
                    items={competencies}
                    onChange={setCompetencyFilter}
                    selectedValue={selectedCompetency}
                    placeholder="Filter by Competency"
                />
                <select
                    className="select"
                    name="task-status"
                    id="task-status"
                    value={selectedStatus}
                    onChange={onChangeStatus}
                >
                    <option value="" disabled>
                        Filter by Status
                    </option>
                    <option value="">All</option>
                    <option value="Complete">Complete</option>
                    <option value="Submitted">Submitted</option>
                    <option value="Revise">Revise</option>
                    <option value="Open">Open</option>
                    <option value="Missing">Missing Work</option>
                    <option value="Incomplete">Incomplete</option>
                    <option value="Needs Help">Needs Help</option>
                </select>
            </div>
            <div className="student-filters__right">
                <div>
                    <label htmlFor="needs-planning-filter" style={{ marginRight: 20 }}>
                        <input
                            id="needs-planning-filter"
                            type="checkbox"
                            name="needs-planning"
                            checked={onlyShowNeedsPlanning}
                            onChange={onChangeNeedsPlanning}
                        />
                        &nbsp;Only show needs planning
                    </label>
                </div>
                <div>
                    <label htmlFor="focus-credit-filter" style={{ marginRight: 20 }}>
                        <input
                            id="focus-credit-filter"
                            type="checkbox"
                            name="focus-credit"
                            checked={onlyShowFocusCredit}
                            onChange={onChangeFocusCredit}
                        />
                        &nbsp;Focus Credit
                    </label>
                </div>
                <div>
                    <button className="button-link" onClick={clearFilters}>
                        Clear filters
                    </button>
                </div>
            </div>
        </div>
    );
};
StudentFilters.propTypes = {};
StudentFilters.defaultProps = {};

export default StudentFilters;
